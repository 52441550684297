import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private apiUrl: string;

  constructor(private http: HttpClient) {
    // Retrieve API URL from environment variables or configuration
    this.apiUrl = 'https://api.example.com/api'; // Default URL
  }

  // Method to perform a GET request
  get<T>(endpoint: string, headers?: { [header: string]: string }): Observable<T> {
    const requestOptions = {
      headers: this.createHttpHeaders(headers)
    };

    return this.http.get<T>(`${this.apiUrl}/${endpoint}`, requestOptions).pipe(
      catchError(this.handleError)
    );
  }

  // Method to perform a POST request
  post<T>(endpoint: string, data: any, headers?: { [header: string]: string }): Observable<T> {
    const requestOptions = {
      headers: this.createHttpHeaders(headers)
    };

    return this.http.post<T>(`${this.apiUrl}/${endpoint}`, data, requestOptions).pipe(
      catchError(this.handleError)
    );
  }

  // Method to perform a PUT request
  put<T>(endpoint: string, data: any, headers?: { [header: string]: string }): Observable<T> {
    const requestOptions = {
      headers: this.createHttpHeaders(headers)
    };

    return this.http.put<T>(`${this.apiUrl}/${endpoint}`, data, requestOptions).pipe(
      catchError(this.handleError)
    );
  }

  // Method to perform a DELETE request
  delete<T>(endpoint: string, headers?: { [header: string]: string }): Observable<T> {
    const requestOptions = {
      headers: this.createHttpHeaders(headers)
    };

    return this.http.delete<T>(`${this.apiUrl}/${endpoint}`, requestOptions).pipe(
      catchError(this.handleError)
    );
  }

  // Method to create HttpHeaders from a plain object
  private createHttpHeaders(headers?: { [header: string]: string }): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    if (headers) {
      for (const header in headers) {
        if (headers.hasOwnProperty(header)) {
          httpHeaders = httpHeaders.set(header, headers[header]);
        }
      }
    }
    return httpHeaders;
  }

  // Error handler method
  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      console.error('An error occurred:', error.error.message);
    } else {
      // Server-side error
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}